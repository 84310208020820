import { first, groupBy, last } from "lodash";

import { sanitizeChartSpec } from "../chart/chartPythonSourceUtils.js";
import {
  newDefaultAreaSeries,
  newDefaultBarSeries,
  newDefaultHistogramSeries,
  newDefaultLineSeries,
  newDefaultPieSeries,
  newDefaultScatterSeries,
  newSuggestibleChart,
} from "../chart/createChartDefaults.js";
import { ChartSemanticColumnMetadata } from "../chart/hql/chartHql.js";
import {
  calciteTypeToChartDataType,
  exploreTruncUnitToChartTimeUnit,
  hqlAggToChartAgg,
} from "../chart/hql/chartHqlUtils.js";
import {
  ChartAggregate,
  ChartAxis,
  ChartAxisWithData,
  ChartDataType,
  ChartFacetDimension,
  ChartSeries,
  ChartSeriesType,
  ChartSort,
  ChartSortDirection,
  ChartSortOrCustomSort,
  ChartTimeUnit,
  LayeredChartSpec,
} from "../chart/types.js";
import {
  DatetimeColumnDisplayFormat,
  NumberColumnDisplayFormat,
} from "../display-table/columnDisplayFormatTypes.js";
import { assertNever } from "../errors.js";
import { notEmpty } from "../notEmpty.js";
import {
  ExploreCellDataframe,
  SemanticDatasetInput,
  generateDataframeNameForSemanticDatasetInput,
  getDataframeName,
} from "../sql/dataSourceTableConfig.js";
import { typedObjectFromEntries } from "../utils/typedObjects.js";

import { ExploreCustomTimeUnit } from "./exploreCustomTimeUnit.js";
import {
  COUNT_STAR_ARG,
  COUNT_STAR_LABEL,
  generateColumnIdForField,
} from "./exploreFieldUtils.js";
import {
  ChartExploreChannel,
  ExploreChannel,
  ExploreChartConfig,
  ExploreChartOrientation,
  ExploreChartSeries,
  ExploreField,
  ExploreSpec,
  ExploreVisualizationType,
  PivotExploreChannel,
} from "./types.js";

export function channelValidForSpec(
  channel: ExploreChannel,
  chartConfig: ExploreChartConfig,
  vizType: ExploreVisualizationType,
): boolean {
  if (vizType === "pivot-table") {
    return PivotExploreChannel.guard(channel);
  } else {
    if (
      isHistogram(chartConfig) &&
      (channel === "color" || channel === "cross-axis")
    ) {
      return false;
    }
    if (isPie(chartConfig) && channel === "base-axis") {
      return false;
    }
    return ChartExploreChannel.guard(channel);
  }
}

export const aggregationChannels: Set<ExploreChannel | undefined> = new Set([
  "value",
  "cross-axis",
  "color",
  "tooltip",
]);

export function maybeInvalidMappingReason({
  channel,
  incomingType,
  isAggregated,
  spec,
}: {
  spec: Pick<ExploreSpec, "chartConfig" | "fields" | "visualizationType">;
  channel: ExploreChannel;
  incomingType: ChartDataType;
  isAggregated: boolean;
}): string | undefined {
  if (!channelValidForSpec(channel, spec.chartConfig, spec.visualizationType)) {
    return `Not valid for this chart`;
  }

  if (isAggregated && !aggregationChannels.has(channel)) {
    return `Cannot add measures.`;
  }

  if (channel === "base-axis" && isHistogram(spec.chartConfig)) {
    if (incomingType !== "number") {
      return "Histograms require a numeric base axis";
    }
  } else if (channel === "color" && isPie(spec.chartConfig)) {
    if (isAggregated) {
      return "Pie color doesn't support aggregation";
    }
    if (incomingType !== "string") {
      return "Pie color must be a string";
    }
  } else if (channel === "h-facet") {
    if (spec.chartConfig.series.length !== 1) {
      return "Can only split a single series";
    }
  } else if (channel === "v-facet") {
    if (spec.chartConfig.series.length !== 1) {
      return "Can only split a single series";
    }
    if (
      spec.fields.some((f) => f.channel === "h-facet") &&
      (spec.chartConfig.facet?.columns ?? 0) > 0
    ) {
      return "Can't vertically split when wrapping a horizontal split";
    }
  }
  return undefined;
}

export function mapExploreFieldsToChartFields(
  spec: ExploreSpec,
  previousSeriesType: ChartSeriesType | undefined,
): ExploreField[] {
  // this function assumes a single series in the spec

  const valueFields = spec.fields.filter((f) => f.channel === "value");
  const rowFields = spec.fields.filter((f) => f.channel === "row");
  const columnFields = spec.fields.filter((f) => f.channel === "column");

  const result: ExploreField[] = [];

  // local helper for to make things more readable
  const fieldByChannel = (
    channel: ExploreChannel,
  ): ExploreField | undefined => {
    return spec.fields.find((f) => f.channel === channel);
  };

  // local helper for to make things more readable
  const pushNonNullToResult = (
    field: ExploreField | undefined,
    channel: ExploreChannel,
  ): void => {
    if (field != null) {
      result.push({ ...field, channel });
    }
  };

  //local helper for to make things more readable
  const goodChannel = (channel: ExploreChannel): boolean => {
    return channelValidForSpec(
      channel,
      spec.chartConfig,
      spec.visualizationType,
    );
  };

  //local helper for to make things more readable
  const goodMapping = (
    field: ExploreField | undefined,
    channel: ExploreChannel,
  ): boolean => {
    return (
      field != null &&
      maybeInvalidMappingReason({
        spec,
        channel,
        incomingType: calciteTypeToChartDataType(field.dataType),
        isAggregated:
          field.aggregation != null || field.fieldType === "MEASURE",
      }) == null
    );
  };

  // for base-axis, cross-axis and color channels we follow a pattern:
  // - check that channelValidForSpec()
  // - iterate through candidates and pick the first isGoodFieldForChannel()
  // - pushNonNullToResult() whatever we found

  // BASE-AXIS
  if (goodChannel("base-axis")) {
    let baseAxisField = undefined;
    if (goodMapping(fieldByChannel("base-axis"), "base-axis")) {
      baseAxisField = fieldByChannel("base-axis");
    } else if (
      previousSeriesType === "pie" &&
      goodMapping(fieldByChannel("color"), "base-axis")
    ) {
      baseAxisField = fieldByChannel("color");
    } else if (goodMapping(last(rowFields), "base-axis")) {
      baseAxisField = rowFields.pop(); // pop for innermost
    } else if (goodMapping(last(columnFields), "base-axis")) {
      baseAxisField = columnFields.pop(); // pop for innermost
    }
    pushNonNullToResult(baseAxisField, "base-axis");
  }

  // CROSS-AXIS
  if (goodChannel("cross-axis")) {
    let crossAxisField = undefined;
    if (goodMapping(fieldByChannel("cross-axis"), "cross-axis")) {
      crossAxisField = fieldByChannel("cross-axis");
    } else if (goodMapping(first(valueFields), "cross-axis")) {
      crossAxisField = valueFields.shift();
    } else {
      if (goodMapping(last(rowFields), "cross-axis")) {
        crossAxisField = rowFields.pop(); // pop last for 'innermost'
      } else if (goodMapping(last(columnFields), "cross-axis")) {
        crossAxisField = columnFields.pop(); // pop last for 'innermost'
      }
      if (crossAxisField != null) {
        // aggregate by default for inferred cross from row/col
        crossAxisField.aggregation =
          getChartDataType(crossAxisField) === "number" ? "Sum" : "Count";
      }
    }
    pushNonNullToResult(crossAxisField, "cross-axis");
  }

  // COLOR
  if (goodChannel("color")) {
    let colorField = undefined;
    if (goodMapping(fieldByChannel("color"), "color")) {
      colorField = fieldByChannel("color");
    } else if (
      isPie(spec.chartConfig) &&
      goodMapping(fieldByChannel("base-axis"), "color")
    ) {
      // when doing (base/cross/no-color chart) -> pie, try to move base to color
      // instead of to tooltip, so we can actually render something
      colorField = fieldByChannel("base-axis");
    } else if (goodMapping(first(valueFields), "color")) {
      colorField = valueFields.shift();
    } else {
      if (goodMapping(last(columnFields), "color")) {
        colorField = columnFields.pop(); // pop for innermost
      } else if (goodMapping(last(rowFields), "color")) {
        colorField = rowFields.pop(); // pop for innermost
      }
      if (colorField != null && !isPie(spec.chartConfig)) {
        // if the selected field is an unaggregated number, agg by sum
        // to avoid tiny marks
        // but not for pies which don't support aggs in color!
        colorField.aggregation =
          colorField.aggregation ??
          (getChartDataType(colorField) === "number" ? "Sum" : undefined);
      }
    }
    pushNonNullToResult(colorField, "color");
  }

  // FACETS
  if (goodChannel("h-facet") && goodChannel("v-facet")) {
    let hFacetField = undefined;
    let vFacetField = undefined;
    if (goodMapping(fieldByChannel("h-facet"), "h-facet")) {
      hFacetField = fieldByChannel("h-facet");
    }
    if (goodMapping(fieldByChannel("v-facet"), "v-facet")) {
      vFacetField = fieldByChannel("v-facet");
    }

    // v gets first crack at columns
    if (vFacetField == null && goodMapping(first(columnFields), "v-facet")) {
      vFacetField = columnFields.shift(); // shift for outermost
    }

    // h gets first crack at rows
    if (hFacetField == null && goodMapping(first(rowFields), "h-facet")) {
      hFacetField = rowFields.shift(); // shift for outermost
    }

    // v gets second crack at rows
    if (vFacetField == null && goodMapping(first(rowFields), "v-facet")) {
      vFacetField = rowFields.shift(); // shift for outermost
    }

    // h gets second crack at columns
    if (hFacetField == null && goodMapping(first(columnFields), "h-facet")) {
      hFacetField = columnFields.shift(); // shift for outermost
    }

    pushNonNullToResult(hFacetField, "h-facet");
    pushNonNullToResult(vFacetField, "v-facet");
  }

  // OPACITY
  if (goodChannel("opacity")) {
    pushNonNullToResult(fieldByChannel("opacity"), "opacity");
  }

  // everything else goes to tooltip
  // this includes left-over row/col/value
  // but also base/cross/color if this is a pie or histogram
  spec.fields.forEach((field: ExploreField) => {
    if (!result.find((f) => f.id === field.id)) {
      result.push({ ...field, channel: "tooltip" as const });
    }
  });
  return result;
}

export function exploreToChartSpec({
  dataframe,
  spec,
}: {
  dataframe: ExploreCellDataframe | null | undefined;
  spec: ExploreSpec;
}): {
  chartSpec: LayeredChartSpec;
  columnMetadata: Record<string, ChartSemanticColumnMetadata>;
} {
  const { chartConfig, visualizationType: vizType } = spec;
  dataframe = SemanticDatasetInput.guard(dataframe)
    ? generateDataframeNameForSemanticDatasetInput(dataframe)
    : dataframe;

  // rename fields so that they can be used as column names in the chart spec
  // without collisions that could arise from same fields from different datasets
  const mappedAndRenamedFields = spec.fields.map((field) => ({
    ...field,
    value: generateColumnIdForField(field),
  }));

  const columnMetadata = typedObjectFromEntries(
    spec.fields.map((field) => {
      return [
        generateColumnIdForField(field),
        {
          name: field.value,
          columnType: field.fieldType,
          queryPath: field.queryPath,
          dataType: field.dataType,
        },
      ];
    }),
  );

  const groupedFields = groupBy(
    mappedAndRenamedFields,
    (field) => field.channel,
  );
  const xAxisField = groupedFields["base-axis"]?.[0];
  const colorField = groupedFields["color"]?.[0]; // for pie
  const hFacetField = groupedFields["h-facet"]?.[0];
  const vFacetField = groupedFields["v-facet"]?.[0];

  if (dataframe == null || vizType === "pivot-table") {
    return { chartSpec: newSuggestibleChart(dataframe), columnMetadata };
  }

  const chartSpec = newSuggestibleChart(dataframe);
  const layer = chartSpec.layers[0]!;
  layer.id = "layer"; // anything deterministic
  layer.dataFrame = getDataframeName(dataframe);

  if (xAxisField != null) {
    layer.xAxis = {
      ...layer.xAxis,
      ...getChartSeriesAxis(xAxisField, spec.chartConfig.orientation),
      dataFrameColumn: xAxisField?.value,
    } as ChartAxisWithData;

    if (layer.xAxis.type === "string") {
      // sort has a broader type for this kind of axis
      layer.xAxis.sort = getFullChartSortFromField(
        xAxisField,
        spec.chartConfig.orientation,
      );
    }
  }

  layer.series = chartConfig.series
    .map((series) =>
      getChartSeries(series, mappedAndRenamedFields, chartConfig),
    )
    .filter(notEmpty);

  if (layer.series[0]?.type === "pie" && colorField != null) {
    layer.xAxis.dataFrameColumn = colorField.value;
  }

  if (
    chartConfig.seriesGroups.length > 1 ||
    (chartConfig.seriesGroups.length > 0 &&
      chartConfig.seriesGroups[0] != null &&
      chartConfig.seriesGroups[0].length > 1)
  ) {
    layer.seriesGroups = chartConfig.seriesGroups;
  }

  if (hFacetField != null) {
    chartSpec.facet = {
      ...(chartSpec.facet ?? {}),
      facetHorizontal: getChartFacet(hFacetField),
    };
  }
  if (vFacetField != null) {
    chartSpec.facet = {
      ...(chartSpec.facet ?? {}),
      facetVertical: getChartFacet(vFacetField),
    };
  }
  if (chartSpec.facet?.facetHorizontal != null) {
    chartSpec.facet.columns = chartConfig.facet?.columns;
  }
  if (chartSpec.facet?.facetVertical != null) {
    chartSpec.facet.sharedY = chartConfig.facet?.sharedY;
  }

  chartSpec.settings = {
    ...chartConfig.settings,
    tooltip: true,
    selectionEnabled: true,
  };
  return {
    chartSpec: sanitizeChartSpec(chartSpec) as LayeredChartSpec,
    columnMetadata,
  };
}

function getValueSortFromField(
  field: ExploreField | undefined,
): ChartSortDirection | undefined {
  if (field?.sort?.mode == null) {
    return undefined;
  }
  switch (field.sort.mode) {
    case "value-ascending":
      return "ascending";
    case "value-descending":
      return "descending";
    case "cross-axis-ascending":
    case "cross-axis-descending":
    case "custom-order":
      // this should never happen
      return undefined;
    default:
      return assertNever(field.sort.mode, field.sort.mode);
  }
}

function getNonCustomSortFromField(
  field: ExploreField | undefined,
  orientation: ExploreChartOrientation = "vertical",
): ChartSort | undefined {
  if (field?.sort?.mode == null) {
    return undefined;
  }
  switch (field.sort.mode) {
    case "value-ascending":
      return "ascending";
    case "value-descending":
      return "descending";
    case "cross-axis-ascending":
      return orientation === "vertical" ? "y" : "x";
    case "cross-axis-descending":
      return orientation === "vertical" ? "-y" : "-x";
    case "custom-order":
      // this should never happen
      return undefined;
    default:
      return assertNever(field.sort.mode, field.sort.mode);
  }
}

function getFullChartSortFromField(
  field: ExploreField,
  orientation: ExploreChartOrientation = "vertical",
): ChartSortOrCustomSort | undefined {
  if (field?.sort?.mode == null) {
    return undefined;
  }
  switch (field.sort.mode) {
    case "value-ascending":
      return "ascending";
    case "value-descending":
      return "descending";
    case "cross-axis-ascending":
      return orientation === "vertical" ? "y" : "x";
    case "cross-axis-descending":
      return orientation === "vertical" ? "-y" : "-x";
    case "custom-order":
      return field.sort.customOrder;
    default:
      return assertNever(field.sort.mode, field.sort.mode);
  }
}

function getChartSeriesAxis(
  field: ExploreField | undefined,
  orientation: ExploreChartOrientation | undefined,
): ChartAxis {
  const axis: ChartAxis = {
    type: getChartDataType(field),
    aggregate: getChartAggregation(field),
    timeUnit: getChartTimeUnit(field),
    style: {
      position: field?.axis?.position,
      labels: {
        angle: field?.axis?.labelAngle,
      },
      grid:
        field?.axis?.grid?.style === "none"
          ? undefined
          : field?.axis?.grid ?? { style: "solid" },
      ticks: field?.axis?.ticks ?? {},
      min: field?.axis?.min,
      max: field?.axis?.max,
      zero: field?.axis?.zero,
    },
    scale: field?.axis?.scale,
    title: field?.title,
    sort: getNonCustomSortFromField(field, orientation),
    referenceLines: field?.axis?.referenceLines,
  };

  if (field?.displayFormat?.columnType === "NUMBER") {
    axis.numberFormat = field.displayFormat;
  } else if (
    field?.displayFormat?.columnType === "DATETIME" &&
    axis.type === "datetime"
  ) {
    axis.datetimeFormat = field.displayFormat;
  }

  return axis;
}

function getChartFacet(field: ExploreField): ChartFacetDimension {
  return {
    type: getChartDataType(field),
    dataFrameColumn: field.value,
    sort: getValueSortFromField(field),
    maxBins: field.bin?.count,
    binStep: field.bin?.size,
    timeUnit: getChartTimeUnit(field),
  };
}

function getChartDataType(field: ExploreField | undefined): ChartDataType {
  if (field == null) {
    return "string";
  }
  return field?.scaleType ?? calciteTypeToChartDataType(field.dataType);
}

function getChartTimeUnit(
  field: ExploreField | undefined,
): ChartTimeUnit | undefined {
  if (field?.truncUnit == null) {
    return;
  }
  if (
    ExploreCustomTimeUnit.guard(field.truncUnit) &&
    field.scaleType === "string"
  ) {
    return field.truncUnit;
  }
  return (field.scaleType ?? "datetime") === "datetime"
    ? exploreTruncUnitToChartTimeUnit(field.truncUnit)
    : undefined;
}

function getChartAggregation(
  field: ExploreField | undefined,
): ChartAggregate | undefined {
  if (field?.value.endsWith(COUNT_STAR_ARG)) {
    return "count";
  } else if (field?.aggregation === "Count") {
    return "valid";
  }
  return field?.aggregation != null
    ? hqlAggToChartAgg(field.aggregation)
    : undefined;
}

function getChartTooltipName(
  field: ExploreField | undefined,
): string | undefined {
  if (
    field?.value.endsWith(COUNT_STAR_ARG) &&
    field?.title === COUNT_STAR_LABEL
  ) {
    return undefined;
  }
  return field?.title;
}

function getChartSeries(
  exploreSeries: ExploreChartSeries,
  fields: ExploreField[],
  chartConfig: ExploreChartConfig,
): ChartSeries | undefined {
  const yFields = fields.filter(
    (f) => f.seriesId === exploreSeries.id && f.channel === "cross-axis",
  );
  const xField = fields.find(
    (f) => f.seriesId === exploreSeries.id && f.channel === "base-axis",
  );

  let series: ChartSeries;
  switch (exploreSeries.type) {
    case "bar": {
      series = newDefaultBarSeries();
      series.layout = exploreSeries.barGrouped
        ? "grouped"
        : exploreSeries.normalize === "base-axis"
          ? "stacked100"
          : "stacked";
      series.orientation = chartConfig.orientation ?? "vertical";
      break;
    }
    case "line": {
      series = newDefaultLineSeries();
      series.interpolate = exploreSeries.lineShape;
      series.point = exploreSeries.linePoint ?? false;
      series.stroke = exploreSeries.lineStroke?.staticValue ?? "solid";
      series.width = exploreSeries.lineWidth?.staticValue;
      break;
    }
    case "area": {
      series = newDefaultAreaSeries();
      series.line = exploreSeries.areaLine ?? true;
      series.point = exploreSeries.linePoint ?? false;
      series.interpolate = exploreSeries.lineShape;
      series.normalize = exploreSeries.normalize === "base-axis";
      break;
    }
    case "scatter": {
      series = newDefaultScatterSeries();
      series.filled = exploreSeries.pointFilled ?? true;
      series.shape = exploreSeries.pointShape?.staticValue;
      series.size = exploreSeries.pointSize?.staticValue;
      break;
    }
    case "histogram": {
      series = newDefaultHistogramSeries();
      if (xField?.bin?.useColumn) {
        series.bin = {
          type: "dataFrameColumn",
        };
      } else if (xField?.bin?.size != null) {
        series.bin = {
          type: "size",
          value: xField.bin.size,
        };
      } else if (xField?.bin?.count != null) {
        series.bin = {
          type: "count",
          value: xField.bin.count,
        };
      }
      series.format =
        exploreSeries.normalize === "facet" ? "percentage" : "count";
      break;
    }
    case "pie":
      series = newDefaultPieSeries();
      series.radius = exploreSeries.radius?.staticValue ?? 0;
      series.showAsPct = exploreSeries.normalize === "facet";
      break;
    default:
      assertNever(exploreSeries.type, exploreSeries.type);
  }

  series.id = exploreSeries.id;
  series.dataFrameColumns =
    yFields.length > 0 ? yFields.map((f) => f.value) : series.dataFrameColumns;
  if (exploreSeries.type !== "histogram") {
    series.axis = getChartSeriesAxis(yFields[0], chartConfig.orientation);
  }
  series.name = exploreSeries.name;
  series.dataLabels = exploreSeries.text?.dataLabels;
  series.totalDataLabels = exploreSeries.text?.totalDataLabels;

  const tooltipFields = fields.filter((f) => f.channel === "tooltip");

  // NB same tooltips for all series :(
  series.tooltip = {
    type: "custom",
    includeAuto: exploreSeries.tooltip?.includeAuto ?? true,
    fields: tooltipFields.map((field) => {
      return {
        id: field.id,
        name: getChartTooltipName(field),
        dataFrameColumn: field.value,
        type: getChartDataType(field),
        aggregate: getChartAggregation(field),
        displayFormat:
          NumberColumnDisplayFormat.guard(field.displayFormat) ||
          DatetimeColumnDisplayFormat.guard(field.displayFormat)
            ? field.displayFormat
            : undefined,
      };
    }),
  };

  const colorField = fields.find(
    (f) => f.seriesId === exploreSeries.id && f.channel === "color",
  );
  const opacityField = fields.find(
    (f) => f.seriesId === exploreSeries.id && f.channel === "opacity",
  );
  if (opacityField != null) {
    series.opacity = {
      type: "dataframe",
      dataType: getChartDataType(opacityField),
      dataFrameColumn: opacityField.value,
    };
  } else if (
    exploreSeries.opacity?.source === "opacity" &&
    exploreSeries.opacity?.staticMode === false
  ) {
    // special state required for the UI state where the user selects 'by data'
    // but hasn't yet selected the data
    series.opacity = {
      type: "dataframe",
      dataType: "number",
    };
  } else if (exploreSeries.opacity?.source === "color") {
    series.opacity = {
      type: "series",
      opacitiesBySeriesValues: colorField?.opacitiesBySeriesValues ?? {},
    };
  } else if (
    exploreSeries.opacity?.source === "base-axis" &&
    exploreSeries.type === "bar"
  ) {
    series.opacity = {
      type: "series",
      opacitiesBySeriesValues: xField?.opacitiesBySeriesValues ?? {},
    };
  } else {
    series.opacity = {
      type: "static",
      value: exploreSeries.opacity?.staticValue ?? 1,
    };
  }

  if (colorField != null) {
    if (
      getChartDataType(colorField) === "string" &&
      colorField.aggregation == null
    ) {
      series.colorDataFrameColumn = colorField.value;
      series.color = {
        type: "series",
        colorsBySeriesValues: colorField.colorsBySeriesValues ?? {},
      };
      series.colorOrder =
        getFullChartSortFromField(colorField, chartConfig.orientation) ??
        "ascending";
    } else {
      const axis = getChartSeriesAxis(colorField, chartConfig.orientation);
      series.color = {
        type: "dataframe",
        dataFrameColumn: colorField.value,
        dataType: axis.type,
        aggregate: axis.aggregate,
        scheme: colorField.axis?.colorScheme,
        reverse: colorField.axis?.reverseColorScheme,
        style:
          colorField.axis?.ticks != null ||
          colorField.axis?.labelAngle != null ||
          colorField.axis?.grid != null ||
          colorField.axis?.min != null ||
          colorField.axis?.max != null ||
          colorField.axis?.zero != null
            ? axis.style
            : undefined,
        scale: axis.scale,
        numberFormat:
          colorField.displayFormat?.columnType === "NUMBER"
            ? colorField.displayFormat
            : undefined,
        datetimeFormat:
          colorField.displayFormat?.columnType === "DATETIME"
            ? colorField.displayFormat
            : undefined,
      };
    }
    series.legendTitle = colorField.title;
  } else if (exploreSeries.color != null) {
    if (
      exploreSeries.type === "bar" &&
      exploreSeries.color?.source === "base-axis"
    ) {
      series.color = {
        type: "series",
        colorsBySeriesValues: xField?.colorsBySeriesValues ?? {},
      };
    } else {
      series.color = {
        type: "static",
        color: exploreSeries.color.staticValue,
      };
    }
  }

  return series;
}

export function isMultiSeries(chartConfig: ExploreChartConfig): boolean {
  return chartConfig.series.length > 1;
}

export function isHistogram(chartConfig: ExploreChartConfig): boolean {
  return chartConfig.series[0]?.type === "histogram";
}

export function isPie(chartConfig: ExploreChartConfig): boolean {
  return chartConfig.series[0]?.type === "pie";
}
