import { partition } from "lodash";

import { calciteTypeToChartDataType } from "../chart/hql/chartHqlUtils.js";
import { ChartDataType } from "../chart/types.js";
import { HqlAggregationFunction } from "../hql/types.js";
import { SemanticDatasetName } from "../idTypeBrands.js";
import { concatRespectingCase } from "../utils/stringUtils.js";

import { hqlAggToPivotAgg } from "./explorePivotUtils.js";
import { SemanticAwareColumn } from "./semanticTypes.js";
import {
  ExploreField,
  ExploreFieldType,
  getExploreTimeUnitName,
} from "./types.js";

/**
 * A field is a aggregated if (1) it is a measure or (2) it is a
 * dimension/column that has an aggregation set
 */
export function isAggregatedField(field: ExploreField): boolean {
  if (field.fieldType === ExploreFieldType.MEASURE) {
    return true;
  }
  return field.aggregation != null;
}

export function getFieldActiveScaleType(field: ExploreField): ChartDataType {
  return field.scaleType ?? calciteTypeToChartDataType(field.dataType);
}

export const COUNT_STAR_ARG = "_HEX_COUNT_STAR_ARG_";
export const COUNT_STAR_LABEL = "Count of Records";

export const BASE_COUNT_STAR_FIELD: Omit<SemanticAwareColumn, "queryPath"> = {
  columnId: COUNT_STAR_ARG,
  columnName: COUNT_STAR_LABEL,
  columnType: "NUMBER",
  fieldType: ExploreFieldType.MEASURE,
  description: undefined,
};

export const getCountStarField = (
  queryPath: SemanticDatasetName[],
): SemanticAwareColumn => ({
  ...BASE_COUNT_STAR_FIELD,
  queryPath,
});

export const CHART_FOLD_KEYS = "CHART_FOLD_KEYS";
export const CHART_FOLD_VALUES = "CHART_FOLD_VALUES";

export const getChartFoldKeysField = (
  label: string | undefined,
): SemanticAwareColumn => ({
  columnId: CHART_FOLD_KEYS,
  columnName: label ?? "Columns",
  columnType: "STRING",
  fieldType: ExploreFieldType.DIMENSION,
  description: undefined,
  queryPath: [],
});

export const isCountStarOutputColumn = (columnId: string) => {
  return columnId.toLowerCase().endsWith(COUNT_STAR_ARG.toLowerCase());
};

interface PartialInputField {
  id: string;
  queryPath: SemanticDatasetName[] | undefined;
}

/**
 * Given an explore field, generates a unique column idenitfier for the field,
 * which includes the query path if it exists.
 */
export function generateColumnIdForField(
  field:
    | Pick<ExploreField, "value" | "queryPath">
    | PartialInputField
    | SemanticAwareColumn,
): string {
  const queryPath = field.queryPath;
  const value =
    "value" in field
      ? field.value
      : "columnId" in field
        ? field.columnId
        : field.id;
  // the first item in the query path is always the base dataset
  // so having only 1 item in the query path doesn't point to any joins
  if (queryPath == null || queryPath.length === 0) {
    return value;
  }
  return [...queryPath, value].join(":");
}

/**
 * This generates a field name that is used to identify fields in the query
 * results. This is used across the viz types to keep column names consistent.
 */
export function getExploreFieldName(
  field: Pick<
    ExploreField,
    "aggregation" | "truncUnit" | "value" | "queryPath" | "fieldType"
  >,
): string {
  let value = generateColumnIdForField(field);

  if (field.fieldType === ExploreFieldType.MEASURE) {
    return value;
  }
  if (field.aggregation != null) {
    value = concatRespectingCase(
      value,
      // use pivot agg to stay in sync with the pivot column name
      `_${hqlAggToPivotAgg(field.aggregation) ?? field.aggregation}`,
      "append",
    );
  }
  if (field.truncUnit != null) {
    const timeUnitName = getExploreTimeUnitName(field.truncUnit);
    value = concatRespectingCase(value, `_${timeUnitName}`, "append");
  }

  return value;
}

export const EXPLORE_CHART_ONLY_AGGREGATIONS: Set<HqlAggregationFunction> =
  new Set(["StdDev", "StdDevPop", "Variance", "VariancePop"]);

export function partitionNestedTableFields(fields: ExploreField[]): {
  aggFields: ExploreField[];
  groupByFields: ExploreField[];
} {
  const [aggFields, groupByFields] = partition(
    fields,
    (field) => field.aggregation != null || field.fieldType === "MEASURE",
  );

  return {
    aggFields,
    groupByFields,
  };
}
